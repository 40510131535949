import ReactDOM from 'react-dom'
import React from 'react'

import App from './app'
import * as serviceWorker from './serviceWorker'
import './assets/styles/index.css'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register()
