import React from 'react'
import styled from 'styled-components'

import Background from './components/background'

const Content = styled.div`
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.h1`
  color: #fff;
  font-family: 'Cinzel', serif;
  font-weight: 700;
`

const Subtitle = styled.h2`
  color: #fff;
  font-family: 'Cinzel', serif;
  font-weight: 400;
`

const Links = styled.div`
  display: flex;
`

const Link = styled.a`
  color: #fff !important;
  margin: 0.5rem;
  transition: 333ms ease-in-out;

  i {
    font-size: 1.5rem;
  }

  &:hover {
    opacity: 0.5;
  }
`

export const App = () => (
  <>
    <Content>
      <Title>zdods</Title>
      <Subtitle>full stack developer</Subtitle>
      <Links>
        <Link href="https://github.com/zdods" target="_blank">
          <i class="fab fa-github-alt"></i>
        </Link>
        <Link href="https://www.linkedin.com/in/zachary-dods/" target="_blank">
          <i class="fab fa-linkedin"></i>
        </Link>
      </Links>
    </Content>
    <Background />
  </>
)

export default App
